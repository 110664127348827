
///// BODY
body {
  background-color: white ! important;
}
// $body-bg: #eeeeee;
$font-size-base: 0.9rem;
$font-size-lg: $font-size-base;
$input-placeholder-color: #DDDDDD;
$border-radius: 0.2rem;
$border-radius-lg: 0.2rem;
$border-radius-sm: 0.2rem;
// $font-family-sans-serif: Verdana,Geneva,sans-serif;
// $font-family-sans-serif: Helvetica Neue,Helvetica,Arial,sans-serif;
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
// $font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";


///// CARD
$card-spacer-x: 0.8rem;
$card-border-color: #e0e0e0;
$card-box-shadow: 0 1rem 3rem rgba(#000000, .3);
.card-header-button {
  --bs-btn-padding-y: .15rem ! important;
  --bs-btn-padding-x: .5rem ! important;
  --bs-btn-font-size: .7rem ! important;
}



///// MODAL
$modal-inner-padding: 0.5rem;
// $modal-dialog-margin-y-sm-up: 0rem;
// $modal-dialog-margin: 0rem;
// $modal-title-line-height: 0.5rem;
$modal-header-padding-y: .8rem;
$modal-header-padding-x: 1.2rem;


///// NAVBAR
$navbar-brand-font-size: 1.5rem;
// $navbar-color: rgba(0, 0, 0, 0.04);
$navbar-color: rgba(248, 249, 250);
// $navbar-color: #ffffff;
// $navbar-brand-margin-end: 0.5rem;
$navbar-brand-margin-end: 0;
// $dropdown-padding-y: .1rem !default;
// $dropdown-divider-margin-y: .01rem !default;
$dropdown-font-size: $font-size-base;
$navbar-nav-link-padding-x: 0;


///// BREADCRUMB
$breadcrumb-padding-y: .75rem !default;
$breadcrumb-padding-x: .25rem !default;
$breadcrumb-bg: #eeeeee !default;
$breadcrumb-text-color: rgb(163, 163, 163) !default;
// $breadcrumb-border-radius: .25rem !default;
$breadcrumb-border-radius: 0 !default;
// $breadcrumb-font-size: .9rem !default;
$breadcrumb-divider: quote(">");
$breadcrumb-margin-bottom: 0 !default;
$breadcrumb-active-color: $breadcrumb-text-color !default;
$breadcrumb-divider-color: $breadcrumb-text-color !default;
.breadcrumb-item a{
  text-decoration: none;
  color: $breadcrumb-text-color;
}

.breadcrumb-item a:hover{
  text-decoration: underline;
  color: $breadcrumb-text-color;
}

